/**
 * @Description:
 * @author Guan LinTao
 * @date 2:30 下午
 * 名片
*/
import http from "../utils/axios";

// 模版列表
export const activityTemplatePage = params => http('GET', '/ares/activityTemplate/page', params)

// 删除活动
export const activityMedia = params => http('GET', '/ares/activityContentMedia/list', params)

// 删除活动
export const activityDel = params => http('POST', '/ares/activity/del', params)

// 新增活动
export const activityCreate = params => http('POST', '/ares/activity/create/', params)

// 活动详情
export const activityDetail = params => http('GET', '/ares/activity/detail', params)

// 编辑活动
export const activityUpdate = params => http('POST', '/ares/activity/update', params)

// 活动二维码
export const activityQrCode = params => http('GET', '/ares/activity/qrcode/', params)

// 活动列表
export const activityPage = params => http('GET', '/ares/activity/page', params)

// 列表接口
export const activityContentList = params => http('GET', '/ares/activityContent/list', params)

// 获取树形
export const activityContentTree = params => http('GET', '/ares/activityContent/tree', params)

// 新增活动内容
export const activityContentAdd = params => http('POST', '/ares/activityContent/add', params)

// 编辑活动内容
export const activityContentEdit = params => http('POST', '/ares/activityContent/edit', params)

// 删除活动内容
export const activityContentDel = params => http('POST', '/ares/activityContent/delete', params)

// 详情
export const activityContentDetail = params => http('GET', '/ares/activityContent/detail', params)

// 编辑
export const activityContentMediaEdit = params => http('POST', '/ares/activityContentMedia/edit', params)

// 排序
export const activityContentMove = params => http('POST', '/ares/activityContent/move', params)

// 授权信息
export const authMpAuthInfo = params => http('GET', '/ares/authMp/authInfo', params)

// 获取授权地址
export const authMpAuthUrl = params => http('GET', '/ares/authMp/authUrl', params)

// 解绑
export const authMpUnbind = params => http('GET', '/ares/authMp/unbind', params)


