<!--主题/系列 新增&编辑-->
<template>
  <Modal
    :visible="value"
    class-name="depart-tree-model"
    :title="title"
    :confirm-loading="loading"
    ok-text="保存"
    cancel-text="取消"
    destroy-on-close
    @ok="handleOk"
    @cancel="handleCancel"
  >

    <a-form-model ref="refForm" class="global-card-form" :model="formInfo" :rules="formRules">


      <a-form-model-item v-if="formInfo.parentId !== '0'" label="所属主题" prop="parentId">
        <Select v-model="formInfo.parentId" size="large" placeholder="请选择所属主题">
          <SelectOption v-for="item in themeList" :key="item.contentId" :value="item.contentId">{{ item.title }}</SelectOption>
        </Select>
      </a-form-model-item>

      <a-form-model-item :label="labelName" prop="title">
        <a-input v-model="formInfo.title" size="large" :placeholder="`请输入${labelName}`" @pressEnter="handleOk" />
      </a-form-model-item>

    </a-form-model>

  </Modal>
</template>

<script>
  import { Modal, Select } from 'ant-design-vue'
  import { activityContentAdd, activityContentEdit } from '@/api/businessCard'
  export default {
    name: 'themeDialog',
    components: { Modal, Select, SelectOption: Select.Option },
    props: {
      value: { type: Boolean, default: false },
      themeList: { type: Array, default: () => [] },
      title: { type: String, default: false },
      info: { type: Object, default: () => {} }
    },
    data() {
      return {
        loading: false,
        formInfo: {
          title: '',
          parentId: null
        },
        formRules: {
          title: [{ required: true, message: '请输入名称' }],
          parentId: [{ required: true, message: '请选择所属主题' }]
        },
        isEditor: false,
        labelName: ''
      }
    },
    watch: {
      'info': function() {
        this.isEditor = this.info.contentId
        this.labelName = `${this.title.substring(2, 4)}名称`
      },
      'value': function() {
        if(this.value) {
          this.init()
        }
      }
    },
    methods: {
      init() {
        this.formInfo.title = this.info.title
        this.formInfo.parentId = this.info.parentId
      },
      handleOk() {
        this.$refs.refForm.validate(valid => {
          if (valid) {
            this.loading = true
            if (this.isEditor) {
              // 编辑
              this.editAPI()
            } else {
              // 新增
              this.addAPI()
            }
          }
        })
      },
      addAPI() {
        activityContentAdd({
          ...this.info,
          ...this.formInfo
        }).then(res => {
          this.$emit('success', {
            type: this.info.dialogType,
            parentId: res.data.parentId === '0' ? res.data.contentId : res.data.parentId,
            contentId: res.data.contentId
          })
          this.$message.success('新增成功')
          this.handleCancel()
        }).catch(() => { this.loading = false })
      },
      editAPI() {
        activityContentEdit({
          ...this.info,
          ...this.formInfo
        }).then(res => {
          this.$emit('success', {
            type: this.info.dialogType,
            parentId: res.data.parentId === '0' ? res.data.contentId : res.data.parentId,
            contentId: this.info.dialogType === 'theme' ? this.info.seriesId : res.data.contentId
          })
          this.handleCancel()
          this.$message.success('编辑成功')
        }).catch(() => { this.loading = false })
      },
      handleCancel() {
        this.loading = false
        this.$emit('input', false)
        this.$refs.refForm.resetFields()
        this.formInfo.parentId = null
      }
    }
  };
</script>

<style lang="scss" scoped>

</style>
